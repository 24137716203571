<template>
	<v-flex v-if="tabs.length > 0" fill-height scroll-y>
		<v-layout column fill-height>
			<v-flex shrink style="width: 100%">
				<v-tabs v-model="activeTab" :class="{ 'v-tabs-once': tabs.length === 1 }" show-arrows grow>
					<v-tabs-slider color="primary"></v-tabs-slider>
					<v-tab v-for="tab in tabs" :key="tab.index" active-class="primary--text">
						<v-icon :color="tab.index === activeTab ? 'primary' : ''" class="mr-2">{{ tab.icon }}</v-icon>
						<v-badge v-model="tab.showBadge">
							<template v-slot:badge>
								<span>{{ tab.badgeValue }}</span>
							</template>
							<span v-text="tab.title" />
						</v-badge>
					</v-tab>
				</v-tabs>
			</v-flex>
			<v-flex fill-height scroll-y>
				<component :is="activeTabComponent" fill-height mt-2 mb-4 :task="task" />
			</v-flex>
		</v-layout>
	</v-flex>
</template>

<script>
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'

export default {
	name: 'TasksManagerTaskDetailsTabs',
	components: {
		TasksManagerTaskDetailsSubtaskList: () => ({
			component: import('@/components/TasksManager/Details/TasksManagerTaskDetailsSubtaskList')
		}),
		TasksManagerTaskDetailsInformationRequestList: () => ({
			component: import('@/components/TasksManager/Details/TasksManagerTaskDetailsInformationRequestList')
		}),
		TasksManagerTaskDetailsServiceRequestList: () => ({
			component: import('@/components/TasksManager/Details/TasksManagerTaskDetailsServiceRequestList')
		}),
		TasksManagerTaskDetailsAttachmentList: () => ({
			component: import('@/components/TasksManager/Details/TasksManagerTaskDetailsAttachmentList')
		})
	},
	mixins: [TasksManagerModuleGuard],
	props: {
		task: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			activeTab: 0
		}
	},
	computed: {
		isMainTask: function () {
			return !this.task.isChild
		},
		isInternal: function () {
			return !this.task.vendor
		},
		isInfoRequest: function () {
			return this.service.isOfTaskInformationRequestType(this.task)
		},
		isServiceRequest: function () {
			return this.service.isOfTaskServiceRequestType(this.task)
		},
		tabs: function () {
			let result = []
			let index = 0
			if (this.isMainTask && !this.isInfoRequest && !this.isServiceRequest) {
				result.push({
					index: index++,
					title: this.$tc('client_workspace.tasks.sub_tasks', this.subTasks.length),
					icon: 'account_tree',
					component: 'TasksManagerTaskDetailsSubtaskList',
					showBadge: this.subTasks.length > 0,
					badgeValue: this.subTasks.length
				})
			}
			if (!this.isInternal && this.isMainTask && !this.isServiceRequest) {
				result.push({
					index: index++,
					title: this.$tc('client_workspace.tasks.information_requests', this.infoRequests.length),
					icon: 'info_outline',
					component: 'TasksManagerTaskDetailsInformationRequestList',
					showBadge: this.infoRequests.length > 0,
					badgeValue: this.infoRequests.length
				})
			}
			if (this.isInfoRequest && !this.isServiceRequest) {
				result.push({
					index: index++,
					title: this.$tc('client_workspace.tasks.attachments', this.task?.number_of_attachments ?? 0),
					icon: 'attachment',
					component: 'TasksManagerTaskDetailsAttachmentList',
					showBadge: this.task?.number_of_attachments ?? 0,
					badgeValue: this.task?.number_of_attachments ?? 0
				})
			}
			if (this.isServiceRequest && this.isMainTask) {
				result.push({
					index: index++,
					title: this.$tc('client_workspace.tasks.service_requests', this.serviceRequests.length),
					icon: 'room_service',
					component: 'TasksManagerTaskDetailsServiceRequestList',
					showBadge: this.serviceRequests.length > 0,
					badgeValue: this.serviceRequests.length
				})
			}
			return result
		},
		activeTabComponent: function () {
			const foundTab = this.tabs.find(tab => tab.index === this.activeTab)
			return foundTab ? foundTab.component : null
		},
		subTasks: function () {
			return this.task.children ? this.task.children.filter(child => this.service.isOfTaskAccountingFirmType(child)) : []
		},
		infoRequests: function () {
			return this.task.children ? this.task.children.filter(child => this.service.isOfTaskInformationRequestType(child)) : []
		},
		serviceRequests: function () {
			return this.task.children ? this.task.children.filter(child => this.service.isOfTaskServiceRequestType(child)) : []
		}
	},
	watch: {
		task: {
			deep: false,
			immediate: false,
			handler: function () {
				this.autoSelectFirstActiveTab()
			}
		}
	},
	created: function () {
		this.autoSelectFirstActiveTab()
	},
	methods: {
		autoSelectFirstActiveTab: function () {
			let maxItems = 0
			this.tabs.map(tab => {
				if (tab.badgeValue > maxItems) {
					maxItems = tab.badgeValue
					this.activeTab = tab.index
				}
			})
		}
	}
}
</script>
<style>
.v-tabs-once .v-tabs__item {
	cursor: default;
}
.v-tabs-once .v-tabs__item .v-ripple__container {
	display: none;
}
</style>
